<template>
  <b-row>
    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-overlay :show="loading">
          <b-form id="form" @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                        label-for="fiscal_year_id">
                        <template v-slot:label>
                        {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        plain
                        id="fiscal_year_id"
                        :options="fiscalYearList"
                        v-model="formData.fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <ValidationProvider name="Fair name" vid="fair_name_id" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                        label-for="fair_name_id">
                        <template v-slot:label>
                        {{ $t('externalTradeFair.fair_name') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        plain
                        id="fair_name_id"
                        :options="fairNameList"
                        v-model="formData.fair_name_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <ValidationProvider :name="$t('globalTrans.trade_license', 'en')" vid="attachment"
                        :rules="`${id ? '' : 'required'}`" v-slot="{ errors }">
                        <b-form-group label-for="attachment">
                            <template v-slot:label>
                                {{ $t('globalTrans.attachment')}} <span v-if="!id" class="text-danger">*</span>
                            </template>
                            <b-form-file plain accept=".doc,.docx,.pdf"
                                v-model="attachment"
                                v-on:change="onFileChange($event)"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-file>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            <div class="invalid-feedback d-block text-muted">
                                {{ $i18n.locale == 'en' ? '[Maximum file size is 500 KB and file format is PNG/JPEG/JPG]' : '[সর্বোচ্চ ফাইল সাইজ 500 KB এবং ফাইল ফরমেট PNG/JPEG/JPG]' }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row class="text-right">
              <b-col class="mt-2 text-right">
                  <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                  <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </ValidationObserver>
    </b-col>
  </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { masterFairEvaluationStoreApi, masterFairEvaluationUpdateApi } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  components: {},
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      baseUrl: internationalTradeFairServiceBaseUrl,
      fairNameList: [],
      formData: {
        fiscal_year_id: 0,
        fair_name_id: 0,
        attachment: ''
      },
      attachment: []
    }
  },
  created () {
    if (this.id) {
        const tmp = this.getStoreData()
        this.formData = tmp
    } else {
        this.formData.fiscal_year_id = this.$store.state.currentFiscalYearId
    }
  },
  watch: {
    'formData.fiscal_year_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fairNameList = this.getFairNameList()
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  methods: {
    onFileChange (e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.formData.attachment = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        this.formData.attachment = ''
      }
    },
    getFairNameList () {
      return this.$store.state.TradeFairService.commonObj.fairNameList.filter(item => item.fiscal_year_id === this.formData.fiscal_year_id).map(el => {
        return { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en }
      })
    },
    async saveUpdate () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        if (this.id) {
            result = await RestApi.putData(internationalTradeFairServiceBaseUrl, `${masterFairEvaluationUpdateApi}/${this.id}`, this.formData)
        } else {
            result = await RestApi.postData(internationalTradeFairServiceBaseUrl, masterFairEvaluationStoreApi, this.formData)
        }

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
            this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-form')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    },
    getStoreData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
